import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs = _resolveComponent("tm-tabs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_forms_empty = _resolveComponent("forms-empty")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, null, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-after": _withCtx(() => [
      _createVNode(_component_tm_tabs, {
        modelValue: _ctx.currentRoute,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRoute) = $event)),
        tabs: _ctx.tabs,
        class: "mt-4",
        "use-router": ""
      }, null, 8, ["modelValue", "tabs"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : (_openBlock(), _createBlock(_component_forms_empty, { key: 1 }))
    ]),
    _: 3
  }))
}