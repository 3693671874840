
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import FormsEmpty from '@/components/pages/services/forms/FormsEmpty.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    FormsEmpty,
    TmTabs,
    PageContent,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const currentRoute = ref('')
    const tabs = [
      { name: 'Click-to-text forms', to: { name: 'base.services.forms' } },
      { name: 'Subscribe forms', to: { name: 'base.services.forms.subscribe' } },
    ]

    return {
      currentRoute,
      isEmptyMode,
      tabs,
    }
  },
})
