
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useFormsOpenModal } from '@/compositions/services/useFormsOpenModal'

export default defineComponent({
  components: {
    TmEmptyState,
    TmButton,
  },
  setup() {
    const { openAddNewModal } = useFormsOpenModal()

    return {
      openAddNewModal,
    }
  },
})
